import { FC } from "react";
import Layout from "../components/app/Layout";
import CreateProfileForm from "../components/profiles/CreateProfileForm";
import { Title1 } from "../components/app/Titles.styled";
import { useParams } from "react-router-dom";

const CreateProfile: FC = () => {
  const params = useParams() as { id?: string };

  return (
    <Layout>
      <Title1>Create Profile</Title1>
      <CreateProfileForm profileId={params?.id}></CreateProfileForm>
    </Layout>
  );
};

export default CreateProfile;
