import { useState, useEffect } from "react";
import { v1 as uuidv1 } from "uuid";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../helpers/localstorage";
import { PROFILES_STORAGE_KEY } from "../constants";

type OnAddProfile = (profile: Profile) => Profile;
type OnEditProfile = (profile: Profile) => Profile;

const useProfiles = (): [
  {
    profiles: Profile[];
  },
  {
    onAddProfile: OnAddProfile;
    onEditProfile: OnEditProfile;
  }
] => {
  const stored = getFromLocalStorage(PROFILES_STORAGE_KEY);

  const [profiles, setProfiles] = useState<Profile[]>(
    stored?.length ? stored : []
  );

  useEffect(() => {
    saveToLocalStorage(PROFILES_STORAGE_KEY, profiles);
  }, [profiles]);

  const onAddProfile: OnAddProfile = (profile) => {
    const createdProfile = {
      id: uuidv1(),
      ...profile,
    };

    setProfiles([...profiles, createdProfile]);

    return createdProfile;
  };

  const onEditProfile: OnAddProfile = (profile) => {
    const otherProfiles = profiles.filter(({ id }) => id !== profile.id);

    setProfiles([...otherProfiles, profile]);

    return profile;
  };

  return [
    {
      profiles,
    },
    {
      onAddProfile,
      onEditProfile,
    },
  ];
};

export default useProfiles;
