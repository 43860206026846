import styled from "@emotion/styled/macro";
import { FC } from "react";

const Layout: FC = ({ children }) => {
  return (
    <AppLayout>
      <HomeImage>
        <source
          srcSet="/assets/background-mobile.jpg"
          media="(max-width: 900px)"
        />
        <img srcSet="/assets/background.jpg" alt="Woods" />
      </HomeImage>
      <ContentWrapper>
        <nav>
          <a href="https://www.mediacurrent.com" title="Go to Media Current">
            <img src="/assets/logo.svg" alt="Media Current Logo" />
          </a>
        </nav>
        {children}
      </ContentWrapper>
    </AppLayout>
  );
};

const AppLayout = styled.div`
  min-height: 100vh;
  @media (min-width: 900px) {
    display: flex;
  }
`;

const HomeImage = styled.picture`
  img {
    height: 30vh;
    width: 100vw;
    object-fit: cover;
  }
  @media (min-width: 900px) {
    img {
      flex: 1;
      height: initial;
      min-height: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  @media (min-width: 900px) {
    padding: 3rem;
    min-width: 50rem;
    margin-right: 10rem;
  }
`;

export default Layout;
