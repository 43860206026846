import styled from "@emotion/styled/macro";
import { FC, FormEvent, useState } from "react";
import { Button } from "../app/Buttons.styled";
import TextInput from "../inputs/TextInput";
import useProfiles from "../../hooks/useProfiles";
import { useHistory } from "react-router";
import useProfile from "../../hooks/useProfile";

interface Props {
  profileId?: Profile["id"];
}

const CreateProfileForm: FC<Props> = ({ profileId }) => {
  const history = useHistory();
  const [{ profile: existingProfile }] = useProfile(profileId);
  const [, { onAddProfile, onEditProfile }] = useProfiles();
  const [profile, setProfile] = useState<Profile>({
    firstName: existingProfile?.firstName || "",
    lastName: existingProfile?.lastName || "",
    bio: existingProfile?.bio || "",
    techs: existingProfile?.techs || [],
  });
  const [editingTech, setEditingTech] = useState<string>("");

  const { firstName, lastName, bio, techs } = profile;
  const isInvalid = !firstName || !lastName || !bio || !techs.length;

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (isInvalid) {
      return;
    }

    const createdProfile = existingProfile
      ? onEditProfile({
          ...existingProfile,
          ...profile,
        })
      : onAddProfile(profile);

    // quick fix just to make sure useEffect has run on the hook, definetely not the best way to it
    setTimeout(() => {
      history.push(`/profiles/${createdProfile.id}`);
    }, 500);
  };

  const onUpdateBasicInfos = (info: keyof Profile, value: string) =>
    setProfile({
      ...profile,
      [info]: value,
    });

  const onAddTech = () => {
    const techToAdd = editingTech.toLowerCase().trim();

    if (!techToAdd || profile.techs.includes(techToAdd)) {
      return;
    }

    setProfile({
      ...profile,
      techs: [...profile.techs, techToAdd],
    });

    setEditingTech("");
  };

  const onRemoveTech = (techToRemove: string) => {
    setProfile({
      ...profile,
      techs: profile.techs.filter(
        (existingTech) => existingTech !== techToRemove
      ),
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        name="firstName"
        label="First Name"
        value={firstName}
        onChange={(e) => onUpdateBasicInfos("firstName", e.currentTarget.value)}
      ></TextInput>
      <TextInput
        name="lastName"
        label="Last Name"
        value={lastName}
        onChange={(e) => onUpdateBasicInfos("lastName", e.currentTarget.value)}
      ></TextInput>
      <TextInput
        name="bio"
        label="Bio"
        value={bio}
        onChange={(e) => onUpdateBasicInfos("bio", e.currentTarget.value)}
        textarea
      ></TextInput>
      <TechsField>
        <TextInput
          name="editingTech"
          label="Tech Interests"
          value={editingTech}
          onChange={(e) => setEditingTech(e.currentTarget.value)}
        ></TextInput>
        <img
          src="/assets/addButton.svg"
          className="add-img-button"
          alt="Add to Tech Interests"
          role="button"
          onClick={onAddTech}
        />
      </TechsField>
      <AddedTechs>
        {techs.map((tech) => (
          <li key={tech}>
            <span>{tech}</span>
            <img
              src="/assets/removeButton.svg"
              className="remove-img-button"
              alt={`Remove ${tech} from Tech Interests`}
              role="button"
              onClick={() => onRemoveTech(tech)}
            />
          </li>
        ))}
      </AddedTechs>
      <Button disabled={isInvalid}>Submit</Button>
    </form>
  );
};

const TechsField = styled.div`
  display: flex;
  align-items: flex-end;
  & > * {
    width: 100%;
  }
  input {
    margin-bottom: 0.5rem;
  }
  .add-img-button {
    width: 50px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`;

const AddedTechs = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    img {
      width: 20px;
      margin-left: 3px;
    }
  }
`;

export default CreateProfileForm;
