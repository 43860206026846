import styled from "@emotion/styled/macro";
import { FC } from "react";
import { Redirect } from "react-router";
import useProfile from "../../hooks/useProfile";
import { Button } from "../app/Buttons.styled";
import { Title1 } from "../app/Titles.styled";

interface Props {
  profileId: Profile["id"];
}

const ProfileDetails: FC<Props> = ({ profileId }) => {
  const [{ profile }, { onEditProfile }] = useProfile(profileId);

  if (!profile) {
    return <Redirect to="/profiles/create"></Redirect>;
  }

  const { firstName, lastName, bio, techs } = profile;

  return (
    <>
      <Title1>{`${firstName} ${lastName}`}</Title1>
      <InfoLabel>A little bit about me:</InfoLabel>
      <InfoValue>{bio}</InfoValue>
      <InfoLabel>Tech I am interested in:</InfoLabel>
      <Techs>
        {techs.map((tech) => (
          <li key={tech}>{tech}</li>
        ))}
      </Techs>
      <Button onClick={onEditProfile}>Edit</Button>
    </>
  );
};

const InfoLabel = styled.span`
  display: block;
  margin: 1rem 0;
  font-size: 20px;
`;

const InfoValue = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 1rem 0;
`;

const Techs = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    list-style: disc;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-left: 10px;
    position: relative;
    &::before {
      content: "";
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: ${(p) => p.theme.colors.primaryDarker};
      border-radius: 50%;
    }
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;

export default ProfileDetails;
