import styled from "@emotion/styled/macro";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import CreateProfile from "./pages/CreateProfile";
import ViewProfile from "./pages/ViewProfile";

function App() {
  return (
    <AppStyled>
      <BrowserRouter>
        <Switch>
          <Route exact path="/profiles/create">
            <CreateProfile></CreateProfile>
          </Route>
          <Route exact path="/profiles/:id">
            <ViewProfile></ViewProfile>
          </Route>
          <Route exact path="/profiles/:id/edit">
            <CreateProfile></CreateProfile>
          </Route>
          <Redirect to="/profiles/create" />
        </Switch>
      </BrowserRouter>
    </AppStyled>
  );
}

const AppStyled = styled.div``;

export default App;
