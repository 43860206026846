import { useHistory } from "react-router";
import useProfiles from "./useProfiles";

type OnEditProfile = () => void;

const useProfile = (
  profileId: Profile["id"]
): [
  {
    profile?: Profile;
  },
  {
    onEditProfile: OnEditProfile;
  }
] => {
  const history = useHistory();
  const [{ profiles }] = useProfiles();
  const profile = profiles.find(({ id }) => id === profileId);

  const onEditProfile: OnEditProfile = () => {
    if (!profile) {
      return;
    }

    history.push(`/profiles/${profile.id}/edit`);
  };

  return [
    {
      profile,
    },
    {
      onEditProfile,
    },
  ];
};

export default useProfile;
