import styled from "@emotion/styled/macro";

export const BaseButton = styled.button`
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.02) translateY(-1px);
  }
  &:focus {
    transform: scale(0.99) translateY(0);
  }
  &:disabled {
    cursor: not-allowed;
    &:hover,
    &:focus {
      transform: initial;
    }
  }
`;

export const Button = styled(BaseButton)`
  background: ${(p) => p.theme.colors.white};
  border: 1px solid;
  border-radius: 50px;
  color: ${(p) => p.theme.colors.primary};
  padding: 1rem 3rem;
  font-size: 1rem;
  display: inline-block;
  margin: 1rem 0;
  text-transform: uppercase;
  box-shadow: ${(p) => p.theme.shadows.bigger};
`;
