import styled from "@emotion/styled/macro";

export const Title1 = styled.h1`
  font-size: 72px;
  font-family: ${(p) => p.theme.fonts.secondary};
  line-height: 1.1;
  margin: 1rem 0;
  padding-bottom: 4rem;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1rem;
    height: 4px;
    width: 250px;
    background-color: ${(p) => p.theme.colors.primaryDarker};
  }
  @media (min-width: 900px) {
    font-size: 98px;
  }
`;
