const designColors = {
  offWhite: "#EFEFEF",
  primary: "#003954",
  primaryDarker: "#012A3A",
};

const defaultTheme = {
  gutter: "1rem",
  fonts: {
    primary: "Fauna One, serif",
    secondary: "Playfair Display, serif",
  },
  colors: {
    ...designColors,
    black: "black",
    white: "white",
    background: "white",
    text: designColors.primary,
    textOnMain: designColors.primary,
    grey: "#f5f5f5",
    greyDarker: "#D9D9D9",
    greyEvenDarker: "#A6A6A6",
  },
  border: {
    big: "15px",
    normal: "10px",
    small: "5px",
    smaller: "3px",
  },
  shadows: {
    normal: "0 1px 3px rgba(75, 75, 75, 0.5)",
    bigger: "0 0px 10px rgba(75, 75, 75, 0.4)",
  },
};

export const theme = defaultTheme;

export type DefaultTheme = typeof theme;
