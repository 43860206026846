import styled from "@emotion/styled/macro";
import React, { FC } from "react";

const Wrapper = styled.div<{ invalid?: boolean }>`
  textarea {
    min-height: 5rem;
  }
  input,
  textarea {
    outline: none;
    color: black;
    width: 100%;
    padding: 1rem 0.5rem;
    border: 2px solid ${({ theme }) => theme.colors.primaryDarker};
    background: white;
    transition: all 0.2s;
    margin: 0.8rem 0 1rem;
    font-family: Arial, Helvetica, sans-serif;
    &:focus {
      transform: scale(1.01) translateY(-1px);
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &::placeholder {
      color: grey;
      font-weight: normal;
    }
  }
  label {
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
  }
`;

interface Props {
  className?: string;
  name: string;
  value: string | number;
  placeHolder?: string;
  label?: string;
  type?: string;
  onChange: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  textarea?: boolean;
}

const TextInput: FC<Props> = ({
  className = "",
  name,
  value,
  placeHolder = "",
  onChange,
  type = "text",
  label = "",
  textarea = false,
}) => {
  const id = `input-${name}`;

  return (
    <Wrapper className={className}>
      {label && <label htmlFor={id}>{label}</label>}
      {textarea ? (
        <textarea
          name={name}
          value={value}
          placeholder={placeHolder}
          onChange={onChange}
          rows={3}
          id={id}
        ></textarea>
      ) : (
        <input
          name={name}
          value={value}
          placeholder={placeHolder}
          onChange={onChange}
          type={type}
          id={id}
        ></input>
      )}
    </Wrapper>
  );
};

export default TextInput;
