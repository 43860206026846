import { FC } from "react";
import { Redirect, useParams } from "react-router";
import Layout from "../components/app/Layout";
import ProfileDetails from "../components/profiles/ProfileDetails";

const ViewProfile: FC = () => {
  const params = useParams() as { id?: string };

  if (!params.id) {
    return <Redirect to="/profiles/create"></Redirect>;
  }

  return (
    <Layout>
      <ProfileDetails profileId={params.id}></ProfileDetails>
    </Layout>
  );
};

export default ViewProfile;
